/* Tridi Dot */
.tridi-dot,
.tridi-pin {
	position: absolute;
	z-index: 2;
	cursor: pointer;
}

.tridi-pin:hover {
	z-index: 3 !important;
}

.tridi-dot:before {
	content: ' ';
	position: absolute;
	z-index: 2;
	left: 0;
	top: 0;
	width: 10px;
	height: 10px;
	background-color: #ff4200;
	border-radius: 50%;
}

.tridi-dot:after {
	content: ' ';
	position: absolute;
	z-index: 1;
	width: 10px;
	height: 10px;
	background-color: #ff4200;
	border-radius: 50%;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) inset;
}
