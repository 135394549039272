/*
  Minimum viable styles for Tridi
*/

.tridi-viewer {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    outline: none;
}

.tridi-draggable-true {
    /* cursor: grab; */
}

.tridi-recording-true {
    cursor: cell !important;
}

.tridi-viewer-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: auto;
}
.tridi-editor-image {
    max-height: 50vh;
    margin: auto;
}
.tridi-viewer-image-shown {
    display: block;
}
.tridi-viewer-image-hidden {
    display: none;
}

/* Hint */

.tridi-hint-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(237, 237, 237, 0.3);
    z-index: 100;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.tridi-hint {
    width: 80px;
    height: 80px;
    background: #333;
    opacity: 1;
    color: #fff;
    border-radius: 50%;
    -webkit-transition: box-shadow 0.1s; /* Safari */
    transition: box-shadow 0.1s;
    position: relative;
}

.tridi-hint:before,
.tridi-hint:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
}

.tridi-hint:before {
    border-width: 15px 15px 15px 0;
    border-color: transparent #fff transparent transparent;
    left: 9px;
}

.tridi-hint:after {
    border-width: 15px 0 15px 15px;
    border-color: transparent transparent transparent #fff;
    right: 9px;
}

.tridi-hint-text {
    display: block;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    margin-top: 8px;
}

.tridi-hint-overlay:hover .tridi-hint,
.tridi-hint-overlay:focus .tridi-hint {
    box-shadow: 0 0 16px 1px #333;
}

/* Loading state */

.tridi-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
}

/* Animations */

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
